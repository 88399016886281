import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { API } from '@aws-amplify/api';
import { createMessage } from '../../graphql/mutations';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Message from '../../models/messages';
import { v1 } from 'uuid';
import { Auth } from 'aws-amplify';

export const PopupCreate = (props) => {
  const [message, setMessage] = useState(new Message());

  const messageCreation = async (message) => {
    await API.graphql({
      query: createMessage,
      variables: {
        input: {
          id: v1,
          message: message.message,
          last_edited_by: Auth.user.attributes.email,
          message_type: message.message_type,
          message_title: message.message_title,
          expiration_date: message.expirationDate,
          start_date: message.start_date,
        },
      },
    });
    setMessage(new Message());
    props.setAddVis(false);
    return message;
  };
  const handleEndDateChange = (expirationDate) => {
    setMessage({ ...message, expirationDate });
  };
  const handleStartDateChange = (start_date) => {
    setMessage({ ...message, start_date });
  };

  return (
    <Modal show={props.vis}>
      <Modal.Header>
        <Modal.Title>Create a new message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Label>
            Create a new message that will be visible on each language
            app's home page. Messages
          </Form.Label>
          <Form.Group
            as={Row}
            controlId="formPlaintextPassword"
            style={{ alignContent: 'center' }}
          >
            <Form.Label column sm="2" style={{ fontWeight: 'bold' }}>
              Title
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="message"
                onChange={(data) => {
                  setMessage({
                    ...message,
                    message_title: data.target.value,
                  });
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label style={{ fontWeight: 'bold' }}>
              Message
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="4"
              value={message?.message}
              onChange={(data) => {
                setMessage({
                  ...message,
                  message: data.target.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextPassword"
            style={{ alignContent: 'center' }}
          >
            <Form.Label column sm="2" style={{ fontWeight: 'bold' }}>
              Type
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="message type"
                onChange={(data) => {
                  setMessage({
                    ...message,
                    message_type: data.target.value,
                  });
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextPassword"
            style={{ alignContent: 'center' }}
          >
            <Form.Label column sm="2" style={{ fontWeight: 'bold' }}>
              Start Date
            </Form.Label>
            <Col sm="10">
              <DatePicker
                selected={message.start_date}
                onChange={(e) => {
                  handleStartDateChange(e);
                }}
                minDate={new Date()}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            controlId="formPlaintextPassword"
            style={{ alignContent: 'center' }}
          >
            <Form.Label column sm="2" style={{ fontWeight: 'bold' }}>
              Expiration Date
            </Form.Label>
            <Col sm="10">
              <DatePicker
                selected={message.expirationDate}
                onChange={(e) => {
                  handleEndDateChange(e);
                }}
                minDate={message.start_date}
              />
            </Col>
          </Form.Group>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => props.setAddVis(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                // API.graphql({query: updateMessage, variables: {input: message}})
                messageCreation(message);
                // props.setVis(false);
                console.log('getting clicked');
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
