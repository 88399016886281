import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { Auth } from 'aws-amplify';

import { withFirebase } from '../Firebase';
import { Checkbox } from '@material-ui/core';
import Toast from 'react-bootstrap/Toast';
import { API } from '@aws-amplify/api';

class AddChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      challenge: this.props.data.challenge,
      vulgarity: this.props.data.vulgarity,
      checked: false,
      show: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.editChallenge = this.editChallenge.bind(this);
    this.editVulgarity = this.editVulgarity.bind(this);
    this.send_challenge = this.send_challenge.bind(this);
    console.log(this.props.challenge);
  }

  editChallenge(event) {
    this.setState({ challenge: event.target.value });
    console.log(event.target.value);
  }
  editVulgarity(event) {
    this.setState({ vulgarity: event.target.value });
    console.log(event.target.value);
  }
  onFormSubmit = (e) => {
    console.log(e);
  };
  handleClose() {
    this.setState({ modalShow: false });
  }
  handleShow() {
    this.setState({
      modalShow: true,
      challenge: this.props.data.challenge,
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  handleCheckboxChange = (event) =>
    this.setState({ checked: event.target.checked });

  setShow() {
    this.setState({ show: false });
  }

  send_challenge = async () => {
    // console.log(this.props.data);
    const email = await Auth.currentUserInfo();
    console.log('email', email.attributes.email);
    if (this.props.data.uid) {
      const challenge = {
        by: email.attributes.email,
        challenge: this.props.data.challenge,
        date: new Date(),
        index: this.props.data.index,
        vulgarity: this.props.data.vulgarity,
      };
      // const apiName = 'moomanagementRest';
      // const path = '/api/submissions';
      // const myInit = {
      //   // OPTIONAL
      //   body: { uid: this.props.data.uid },
      //   headers: {}, // OPTIONAL
      // };
      // API.patch(apiName, path, myInit).then(() => {
      //   this.setState({ modalShow: false });
      //   this.props.updateData(this.props.data.uid);
      // });
    }
  };
  render() {
    return (
      <>
        <Button variant="outline-success" onClick={this.handleShow}>
          {this.props.title}
        </Button>

        <Modal show={this.state.modalShow} onHide={this.handleClose}>
          <Toast
            style={{ position: 'absolute' }}
            onClose={() => this.setShow(false)}
            show={this.state.show}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">Success</strong>
              <small>There we go!</small>
            </Toast.Header>
            <Toast.Body>Fuck yeah, just a few more!</Toast.Body>
          </Toast>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>{this.props.title}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={this.state.challenge}
                  onChange={this.editChallenge}
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Vulgarity</Form.Label>
                <Form.Control
                  as="select"
                  onChange={this.editVulgarity}
                  defaultValue={this.state.vulgarity}
                >
                  <option value={1}>PG</option>
                  <option value={2}>PG-13</option>
                  <option value={3}>R</option>
                  <option value={4}>XXX</option>
                </Form.Control>
              </Form.Group>
              <Modal.Footer>
                <label
                  style={{ alignItems: 'left', alignSelf: 'left' }}
                >
                  <span>Submit another</span>
                  <Checkbox
                    checked={this.state.checked}
                    onChange={this.handleCheckboxChange}
                  />
                </label>
                <Button
                  variant="secondary"
                  onClick={this.handleClose}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={this.send_challenge}
                >
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withFirebase(AddChallenge);
