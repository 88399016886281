import React, { useEffect, useState } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';

import LanguageAppsButton from '../LanguageAppsButton';
import MoomosaButton from '../MoomosaButton';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { LINKS } from '../../constants/routes';
import { Button } from 'react-bootstrap';

function Navigation() {
  let [authUser, setUser] = useState(null);
  useEffect(() => {
    let updateUser = async (authState) => {
      try {
        let authUser = await Auth.currentAuthenticatedUser();
        setUser(authUser);
      } catch {
        setUser(null);
      }
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser); // cleanup
  }, []);
  return authUser ? (
    <div style={{ marginBottom: 75 }}>
      <NavigationAuth authUser={authUser} />
    </div>
  ) : (
    <NavigationNonAuth />
  );
}

const NavigationAuth = ({ authUser }) => (
  <div style={{ flex: 1, flexDirection: 'row' }}>
    <Navbar bg="light" expand="lg" fixed="top">
      <Navbar.Brand href="/">Moo Management</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <div className="media-left media-middle">
            <a className="navbar-brand" href="/">
              <img
                alt="Brand"
                src={require('../../assets/cubey-slow.gif')}
                style={{ width: 50, height: 50 }}
              />
            </a>
          </div>
        </Nav>
        <Form inline>
          <Button
            variant="outline-danger"
            style={{ marginRight: 10 }}
            href={LINKS}
          >
            Links
          </Button>
          <LanguageAppsButton variant="outline-danger" />
          <MoomosaButton style={{ marginRight: 10 }} />
          <AmplifySignOut />
        </Form>
      </Navbar.Collapse>
    </Navbar>
  </div>
);

const NavigationNonAuth = () => <ul></ul>;

export default Navigation;
