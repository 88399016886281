import React from 'react';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Dropdown } from 'react-bootstrap';

const MoomosaButton = () => (
  <Dropdown style={{ marginRight: 10 }}>
    <Dropdown.Toggle variant="success" id="dropdown-basic">
      Moomosa
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href={ROUTES.SUBMISSIONS}>
        Submissions
      </Dropdown.Item>
      <Dropdown.Item href={ROUTES.HOME}>Challenges</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

export default withFirebase(MoomosaButton);
