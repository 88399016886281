import React from 'react';
import Messages from '../Messages';

const HomePage = () => (
  <div>
    <Messages />
  </div>
);

export default HomePage;
