/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:d469bd02-91b8-4e7f-b030-1fea5e751d57",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_HriPbOE7k",
    "aws_user_pools_web_client_id": "4crcge01j93gk20gqun2nb7si",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://rfqcygk34vgmxnp2lzfptgunae.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "moomanagementRest",
            "endpoint": "https://2zgfdf1kt0.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
