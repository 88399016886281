import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { listMessages } from '../../graphql/queries';
import { API } from '@aws-amplify/api';

import Button from '@material-ui/core/Button';
import { PopupEdit } from '../PopupEdit';
import { PopupCreate } from '../PopupCreate';
import LAMessage from '../LAMessage';
import Message from '../../models/messages';

const classes = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const SettingsPage = () => {
  const [message, setMessage] = useState(null);
  const [messageArray, setMessageArray] = useState();
  const [addVis, setAddVis] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    getMessages().then((messageArray) => {
      let mappedArray = messageArray.map(
        (chunk) =>
          new Message(
            chunk.message,
            chunk.message_title,
            chunk.message_type,
            chunk.start_date,
            chunk.expiration_date,
            chunk.last_edited_by,
            chunk.id,
          ),
      );
      setMessageArray(mappedArray);
    });
  }, [show, addVis, message]);
  const forceRefresh = () => {
    console.log('forcing refresh');
    setShow(!show);
  };
  return (
    <div>
      <h2 style={{ alignSelf: 'center', width: '100%' }}>
        Language Apps
      </h2>
      <Button
        style={{ margin: 10 }}
        variant="contained"
        color={'primary'}
        onClick={() => setAddVis(true)}
      >
        Add New Message
      </Button>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {messageArray ? (
          messageArray.map((chunk, index) => {
            return (
              <LAMessage
                message={chunk}
                forceRefresh={forceRefresh}
                setMsg={setMessage}
              />
            );
          })
        ) : (
          <div />
        )}
      </div>
      {message === null ? (
        <div />
      ) : (
        <PopupEdit
          message={message}
          title={'Edit'}
          setMessage={setMessage}
        />
      )}
      <PopupCreate vis={addVis} setAddVis={setAddVis} />
    </div>
  );
};

const getMessages = async () => {
  const message = await API.graphql(graphqlOperation(listMessages));
  return message.data.listMessages.items;
};

export default SettingsPage;
