import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { updateMessage } from '../../graphql/mutations';

export const PopupEdit = (props) => {
  const [message, setMessage] = useState(props.message);
  const updateMessageFunc = (message) => {
    return API.graphql(
      graphqlOperation(updateMessage, {
        input: { id: message.id, message: message.message },
      }),
    );
  };
  return (
    <Modal show={message}>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>{props.title}</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              value={message.message}
              onChange={(data) => {
                console.log('data', data.target.value);
                setMessage({
                  ...message,
                  message: data.target.value,
                });
              }}
            />
          </Form.Group>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => props.setMessage(null)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateMessageFunc(message).then(() => {
                  props.setMessage(null);
                });
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
