export default class Message {
  constructor(
    message,
    message_title,
    message_type,
    start_date,
    expiration_date,
    last_edited_by,
    id,
  ) {
    this.message = message;
    this.message_title = message_title;
    this.message_type = message_type;
    this.start_date = start_date;
    this.expiration_date = expiration_date;
    this.last_edited_by = last_edited_by;
    this.id = id;
  }
}
