import React from 'react';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Dropdown } from 'react-bootstrap';

const LanguageAppsButton = ({ firebase }) => (
  <Dropdown style={{ marginRight: 10 }}>
    <Dropdown.Toggle variant="info" id="dropdown-basic">
      Language Apps
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href={ROUTES.LA_SETTINGS}>
        Settings
      </Dropdown.Item>
      <Dropdown.Item href={ROUTES.FLASHCARDS}>
        Flashcards
      </Dropdown.Item>
      <Dropdown.Item href={ROUTES.HOME}>Analytics</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

export default withFirebase(LanguageAppsButton);
