import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Navigation from '../Navigation';
// import LandingPage from '../Landing';
import HomePage from '../Home';

import * as ROUTES from '../../constants/routes';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Submissions from '../Submissions';
import SettingsPage from '../Settings';
import Flashcards from '../Flashcards';
import LinksScreen from '../../screens/LinksScreen';

const App = () => (
  <Router>
    <div style={{ flexDirection: 'column' }}>
      <Navigation />
      <hr />
      <Switch>
        <Route
          exact
          path={ROUTES.FLASHCARDS}
          component={Flashcards}
        />

        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route
          exact
          path={ROUTES.SUBMISSIONS}
          component={Submissions}
        />
        <Route
          exact
          path={ROUTES.LANGUAGEAPPS}
          component={SettingsPage}
        />
        <Route
          exact
          path={ROUTES.LA_SETTINGS}
          component={SettingsPage}
        />
        <Route exact path={ROUTES.LINKS} component={LinksScreen} />
      </Switch>
    </div>
  </Router>
);

export default withAuthenticator(App);
