import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import Button from 'react-bootstrap/Button';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { listLaPhrases } from '../../graphql/queries';
import AddFlashcard from '../AddFlashcard'
import { createLaPhrases, deleteLaPhrases } from '../../graphql/mutations';
import { v1 } from 'uuid';
import Modal from 'react-bootstrap/Modal';

const Flashcards = () => {
  const [flashcards, setFlashcards] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [row, setRow] = useState({ uuid:'', phrase: '' })
  const flipRefresh = ()=> {
    setRefresh(!refresh)
  }
  useEffect(() => {
    API.graphql(
      graphqlOperation(listLaPhrases, {
        limit: 1000,
      }),
    ).then((flashcards) => {
      setFlashcards(flashcards?.data?.listLaPhrases?.items);
    });
  }, []);


  useEffect(() => {
    API.graphql(
      graphqlOperation(listLaPhrases, {
        limit: 1000,
      }),
    ).then((flashcards) => {
      setFlashcards(flashcards?.data?.listLaPhrases?.items);
    });
  }, [refresh]);

  const filterCaseInsensitive = (filter, row) => {
    const id = filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
      return row[id] !== undefined
        ? String(row[id].toLowerCase()).includes(
          filter.value.toLowerCase(),
        )
        : true;
    }
  };
  const remove_challenge = async (row) => {
    if (row.uuid) {
      console.log(row.uuid)
      try {
        await API.graphql(graphqlOperation(deleteLaPhrases, {
          input: {
            uuid: row.uuid,
            phrase: row.phrase
          }
        }))
      } catch(e){
        console.log(e)
      }
    }
    flipRefresh()
  }
  return (
    <div style={{ alignContent: 'center', alignItems: 'center' }}>
      <br />

      <br />
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <AddFlashcard title={'Add Flashcard'} data={{ challenge: '' }} flipRefresh={flipRefresh}/>
      </div>
      <br />

      <ReactTable
        data={flashcards}
        headerStyle={{ position: 'fixed' }}
        filterable
        defaultFilterMethod={filterCaseInsensitive}
        columns={[
          {
            columns: [
              {
                Header: 'UUID',
                id: 'uuid',
                accessor: (d) => d.uuid,
              },
              {
                Header: 'Phrase',
                id: 'phrase',
                style: { 'white-space': 'unset' },
                accessor: (d) => d.phrase,
              },
              {
                Header: 'Created At',
                id: 'createdAt',
                accessor: (d) => d.createdAt,
              },
              {
                Header: 'Type',
                id: 'type',
                accessor: (d) => d.type,
              },
              {
                Header: '',
                id: 'Delete',
                filterable: false,
                accessor: (row) => (
                  <div>
                    <Button
                      variant="outline-danger"
                      style={{ cursor: 'pointer' }}
                      onClick={async () => {
                        setShowDelete(true)
                        setRow(row)
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                ),
              },
              {
                Header: '',
                id: 'Edit',
                filterable: false,
                accessor: (row) => <AddFlashcard data={row} title={'Edit'} flipRefresh={flipRefresh} refresh={refresh}/>,
              },
            ],
          },
        ]}
        defaultPageSize={20}
        style={{
          position: 'sticky',
        }}
        className="-striped -highlight"
      />
      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>WARNING!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete that?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDelete(false)}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if(row){
                remove_challenge(row);
              }
              setShowDelete(false);
            }
            }
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Flashcards;
