import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Auth } from 'aws-amplify';

import { withFirebase } from '../Firebase';
import { Checkbox } from '@material-ui/core';
import Toast from 'react-bootstrap/Toast';
import { API } from '@aws-amplify/api';
import { createLaPhrases, updateLaPhrases } from '../../graphql/mutations';
import { v1 } from 'uuid';
import { graphqlOperation } from '@aws-amplify/api-graphql';


const AddFlashcard = ({data, flipRefresh, refresh, title}) =>  {


  const [modalShow, setModalShow] = useState(false)
  const [flashcard, setFlashcard] = useState('')
  const [checked, setChecked] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setModalShow(false)
  }
  useEffect(()=> {
    setFlashcard(data.phrase)
  }, [])
  const editFlashcard = (event) => {
    setFlashcard(event.target.value)
  }
  const handleShow = () => {
    setModalShow(true)
  }
  const handleCheckboxChange =()=>{
    setChecked(!checked)
  }
  const send_flashcard = async () => {
    console.log('sending flashcard');
    const email = await Auth.currentUserInfo();
    console.log('email', email.attributes.email, flashcard);
    if (flashcard !=='') {
      const type = flashcard.includes(' ') ? "Phrase" : "Word"
      console.log('type', type, v1())
      try {
        API.graphql({
          query: createLaPhrases, variables: {
            input: {
              uuid: v1(),
              phrase: flashcard,
              type: type,
              createdAt: new Date()
            },
          }
        }).then(() => {
          console.log('closing')
          if(!checked){
            handleClose()
          }
          setShow(true)
          setFlashcard('')
        })
      } catch(e){
        console.log(e)
      }
    }
  };

  const updateFlashcard = async () => {
    console.log('update flashcard');

    // console.log(this.data);
    // const email = await Auth.currentUserInfo();
    // console.log('email', email.attributes.email, flashcard);
    if (data.uuid) {
      const type = flashcard.includes(' ') ? "Phrase" : "Word"
      try {
        API.graphql(
        graphqlOperation(updateLaPhrases, {input: {
                  uuid: data.uuid,
                  phrase: flashcard,
                  type: type
          }})

        ).then(() => {
          console.log('closing')
            handleClose()
          setShow(true)
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <>
      <Button variant="outline-success" onClick={handleShow}>
        {title}
      </Button>

      <Modal show={modalShow} onHide={handleClose}>
        <Toast
          style={{ position: 'fixed', justifySelf: 'end' }}
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Success</strong>
            <small>There we go!</small>
          </Toast.Header>
          <Toast.Body>Fuck yeah, just a few more!</Toast.Body>
        </Toast>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>{title}</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                value={flashcard}
                onChange={editFlashcard}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
            </Form.Group>
            <Modal.Footer>
              {data.uuid !== undefined ? (<div/>): (<label
                style={{ alignItems: 'left', alignSelf: 'left' }}
              >
                <span>Submit another</span>
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
              </label>)}

              <Button
                variant="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={async () => {
                  data.uuid ? await updateFlashcard() : await send_flashcard()
                  flipRefresh()
                }
                }
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default withFirebase(AddFlashcard);
