import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import MessageList from './MessageList';
import ReactTable from 'react-table';
import { makeData, Logo, Tips } from './Utils';
import _ from 'lodash';
import 'react-table/react-table.css';
import AddChallenge from '../AddChallenge';
import Button from 'react-bootstrap/Button';
import AcceptChallenge from '../AcceptChallenge';
import { Modal } from 'react-bootstrap';
import { API } from '@aws-amplify/api';

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: true,
      messages: [],
      limit: 5,
      data: [],
      showDelete: false,
    };
    this.updateData = this.updateData.bind(this);
    // this.remove_challenge = this.remove_challenge.bind(this);
  }

  componentDidMount() {
    this.onListenForMessages();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });
    const apiName = 'moomanagementRest';
    const path = '/api/submissions';
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
    };

    API.get(apiName, path, myInit)
      .then((challengeObject) => {
        console.log('challengeObject', challengeObject);
        // Add your code here
        if (challengeObject) {
          const challengeList = Object.keys(challengeObject).map(
            (key) => ({
              ...challengeObject[key],
              uid: key,
            }),
          );

          this.setState({
            challenges: challengeList,
            loading: false,
          });
          console.log('Loaded dataL:' + challengeList[0].vulgarity);
        } else {
          this.setState({ challenges: null, loading: false });
        }
      })
      .catch((error) => {
        console.log('error message', error.response);
      });
  };

  componentWillUnmount() {
    // this.props.firebase.submittedChallenges().off();
  }

  onChangeText = (event) => {
    this.setState({ text: event.target.value });
  };

  onCreatechallenge = (event, authUser) => {
    this.props.firebase.submittedChallenges().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditchallenge = (challenge, text) => {
    const { uid, ...challengeSnapshot } = challenge;

    this.props.firebase.challenge(challenge.uid).set({
      ...challengeSnapshot,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };
  rating(number) {
    if (number == 1) {
      return 'PG';
    } else if (number == 2) {
      return 'PG-13';
    } else if (number == 3) {
      return 'R';
    } else if (number == 4) {
      return 'XXX';
    }
  }
  onRemovechallenge = (uid) => {
    // this.props.firebase.challenge(uid).remove();
    const apiName = 'moomanagementRest';
    const path = '/api/submissions';
    const myInit = {
      // OPTIONAL
      body: { uid: uid },
      headers: {}, // OPTIONAL
    };

    API.del(apiName, path, myInit)
      .then((challengeObject) => {
        console.log('challengeObject', challengeObject);
        // Add your code here
        if (challengeObject) {
          console.log('this.state.uidDelete', this.state.uidDelete);
          const challengeList = this.state.challenges.filter(
            (item) => item.uid !== this.state.uidDelete,
          );
          console.log('challengeList', challengeList);
          this.setState({
            challenges: challengeList,
            showDelete: false,
          });
          console.log('Loaded dataL:' + challengeList);
        } else {
          this.setState({ challenges: null, loading: false });
        }
      })
      .catch((error) => {
        console.log('error message', error.response);
      });
  };
  // remove_challenge() {
  //   this.props.firebase
  //     .submittedChallenges()
  //     .child(this.state.uidDelete)
  //     .remove()
  //     .then(() => {})
  //     .then(() => {
  //       this.setState({ showDelete: false });
  //     });
  // }
  onNextPage = () => {
    this.setState(
      (state) => ({ limit: state.limit + 5 }),
      this.onListenForchallenges,
    );
  };
  rating(number) {
    if (number == 1) {
      return 'PG';
    } else if (number == 2) {
      return 'PG-13';
    } else if (number == 3) {
      return 'R';
    } else if (number == 4) {
      return 'XXX';
    }
  }
  handleCloseDelete() {
    this.setState({ showDelete: false });
  }
  handleShowDelete(uidDelete) {
    this.setState({ showDelete: true, uidDelete: uidDelete });
  }

  edit_challenge(key) {
    this.setState({
      open: true,
      challenge: key.challenge,
      value: key.vulgarity,
      key: key.key,
    });
  }
  // submit_edit() {
  //   if (this.state.key) {
  //     this.props.firebase
  //       .submittedChallenges()
  //       .child(this.state.key)
  //       .update({
  //         challenge: this.state.challenge,
  //         vulgarity: this.state.value,
  //       });
  //   }
  // }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleChallenge(event) {
    this.setState({ challenge: event.target.value });
  }
  updateData(uid) {
    const data = this.state.challenges.filter(
      (chunk) => chunk.uid !== uid,
    );
    this.setState({ challenges: data });
  }
  render() {
    // console.log(this.props.firebase.user.username)

    const { text, challenges, loading } = this.state;
    const columns = [
      {
        Header: 'Name',
        accessor: 'name', // String-based value accessors!
      },
      {
        Header: 'Age',
        accessor: 'age',
        Cell: (props) => (
          <span className="number">{props.value}</span>
        ), // Custom cell components!
      },
      {
        id: 'friendName', // Required because our accessor is not a string
        Header: 'Friend Name',
        accessor: (d) => d.vulgarity, // Custom value accessors!
      },
      {
        Header: (props) => <span>Friend Age</span>, // Custom header components!
        accessor: 'friend.age',
      },
    ];
    return (
      <div>
        {loading && <div>Loading ...</div>}

        {challenges && (
          <div>
            <br />

            <br />
            <ReactTable
              data={this.state.challenges}
              headerStyle={{ position: 'fixed' }}
              filterable={true}
              columns={[
                {
                  columns: [
                    {
                      Header: 'Challenge',
                      id: 'challenge',
                      style: { 'white-space': 'unset' },
                      accessor: (d) => d.challenge,
                    },
                    {
                      Header: 'Vulgarity',
                      id: 'vulgarity',
                      accessor: (d) => this.rating(d.vulgarity),
                    },
                    {
                      Header: 'IG',
                      id: 'igHandle',
                      accessor: (d) => d.igHandle,
                    },
                    {
                      Header: 'Date',
                      id: 'Date',
                      accessor: (d) => d.date,
                    },

                    {
                      Header: 'By',
                      id: 'By',
                      accessor: (d) => d.by,
                    },
                    {
                      Header: '',
                      id: 'Delete',
                      filterable: false,
                      accessor: (row) => (
                        <div>
                          <Button
                            variant="outline-danger"
                            onClick={() =>
                              this.handleShowDelete(row.uid)
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Delete
                          </Button>
                        </div>
                      ),
                    },
                    // {
                    //   Header: "",
                    //   id: "Edit",
                    //   accessor: row => (
                    //     <AddChallenge data = {row} title = {'Edit'} />
                    //   )
                    // },
                    {
                      Header: '',
                      id: 'Accept',
                      filterable: false,
                      accessor: (row) => (
                        <AcceptChallenge
                          data={row}
                          updateData={this.updateData}
                          title={'Accept'}
                        />
                      ),
                    },
                  ],
                },
              ]}
              defaultPageSize={20}
              style={{
                height: '400px',
                position: 'sticky',
              }}
              className="-striped -highlight"
            />
            <br />
            <Modal
              show={this.state.showDelete}
              onHide={() => this.handleCloseDelete()}
            >
              <Modal.Header closeButton>
                <Modal.Title>WARNING!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete that?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.handleCloseDelete()}
                >
                  No
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.onRemovechallenge(this.state.uidDelete)
                  }
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}

        {!challenges && loading && (
          <div style={{ marginTop: 20 }}>Loading ...</div>
        )}
      </div>
    );
  }
}

export default withFirebase(Messages);
