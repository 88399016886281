import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { deleteMessage } from '../../graphql/mutations';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';

export default function LAMessage(props) {
  useEffect(() => {}, []);
  return (
    <Card
      style={{
        width: 300,
        height: 300,
        minWidth: 300,
        maxMidth: 300,
        backgroundColor: '#ffe6e6',
        marginLeft: 10,
        marginBottom: 10,
        alignItems: 'flex-end',
        flexDirection: 'column',
        display: 'grid',
      }}
    >
      <CardHeader
        title={props.message.message_title}
        onClick={props.onClick}
      />
      <CardContent style={{ flex: 1, flexDirection: 'column' }}>
        <Typography color="textSecondary" gutterBottom>
          {props.message.message}
        </Typography>
      </CardContent>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          onClick={() => {
            console.log('tesdgfsgfgdfg');
            props.setMsg(props.message);
          }}
          style={{ alignSelf: 'flex-end' }}
        >
          Edit
        </Button>
        <Button
          onClick={() => {
            console.log('tesdgfsgfgdfg', props.message);
            deleteCard(props.message.id).then(() => {
              props.forceRefresh();
            });
          }}
          style={{ alignSelf: 'flex-end' }}
        >
          Delete
        </Button>
      </div>
    </Card>
  );
}

const deleteCard = async (id) => {
  console.log('id', id);
  await API.graphql(
    graphqlOperation(deleteMessage, { input: { id: id } }),
  );
};

LAMessage.propTypes = {
  forceRefresh: PropTypes.func,
  message: PropTypes.object,
  setMsg: PropTypes.func,
};
