import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function LinksScreen() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs>
          <Paper className={classes.paper}>
            <a
              href="https://airflow.feralcat.xyz"
              target={'_blank'}
              style={{ fontSize: 30 }}
            >
              Apache Airflow
            </a>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>
            <a
              href="https://feralcat.atlassian.net/jira/boards"
              target={'_blank'}
              style={{ fontSize: 30 }}
            >
              Feral Cat's Jira
            </a>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>
            <a
              href="https://jenkins.feralcat.xyz"
              target={'_blank'}
              style={{ fontSize: 30 }}
            >
              Jenkins
            </a>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <Paper className={classes.paper}>
            <a
              href="https://sentry.feralcat.xyz"
              target={'_blank'}
              style={{ fontSize: 30 }}
            >
              Sentry for Error Reporting
            </a>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <a
              href="https://feral-cat.myshopify.com/admin"
              target={'_blank'}
              style={{ fontSize: 30 }}
            >
              Feral Cat's Swag Store
            </a>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>
            <a
              href="https://feralcat.slack.com"
              target={'_blank'}
              style={{ fontSize: 30 }}
            >
              Slack
            </a>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs>
          <Paper className={classes.paper}>
            <a
              href="https://feralcat.xyz"
              target={'_blank'}
              style={{ fontSize: 30 }}
            >
              Feral Cat's Blog
            </a>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>
            <a
              href="https://analytics.google.com/analytics/web/#/report-home/a52976800w174129239p173377076"
              target={'_blank'}
              style={{ fontSize: 30 }}
            >
              Google Analytics
            </a>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default LinksScreen;
