/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLaPhrases = /* GraphQL */ `
  mutation CreateLaPhrases($input: CreateLaPhrasesInput!) {
    createLaPhrases(input: $input) {
      createdAt
      phrase
      type
      uuid
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $condition: ModelMessageConditionInput
    $input: CreateMessageInput!
  ) {
    createMessage(condition: $condition, input: $input) {
      createdAt
      expiration_date
      id
      last_edited_by
      message
      message_title
      message_type
      start_date
      updatedAt
    }
  }
`;
export const deleteLaPhrases = /* GraphQL */ `
  mutation DeleteLaPhrases($input: DeleteLaPhrasesInput!) {
    deleteLaPhrases(input: $input) {
      createdAt
      phrase
      type
      uuid
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $condition: ModelMessageConditionInput
    $input: DeleteMessageInput!
  ) {
    deleteMessage(condition: $condition, input: $input) {
      createdAt
      expiration_date
      id
      last_edited_by
      message
      message_title
      message_type
      start_date
      updatedAt
    }
  }
`;
export const updateLaPhrases = /* GraphQL */ `
  mutation UpdateLaPhrases($input: UpdateLaPhrasesInput!) {
    updateLaPhrases(input: $input) {
      createdAt
      phrase
      type
      uuid
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $condition: ModelMessageConditionInput
    $input: UpdateMessageInput!
  ) {
    updateMessage(condition: $condition, input: $input) {
      createdAt
      expiration_date
      id
      last_edited_by
      message
      message_title
      message_type
      start_date
      updatedAt
    }
  }
`;
