import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import AddChallenge from '../AddChallenge';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { API } from '@aws-amplify/api';

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: true,
      messages: [],
      limit: 5,
      data: [],
    };
    this.remove_challenge = this.remove_challenge.bind(this);
  }

  componentDidMount() {
    this.onListenForMessages();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });
    const apiName = 'moomanagementRest';
    const path = '/api/challenges';
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
    };

    API.get(apiName, path, myInit)
      .then((challengeObject) => {
        console.log('challengeObject', challengeObject);
        // Add your code here
        if (challengeObject) {
          const challengeList = Object.keys(challengeObject).map(
            (key) => ({
              ...challengeObject[key],
              uid: key,
            }),
          );

          this.setState({
            challenges: challengeList,
            loading: false,
          });
          console.log('Loaded dataL:' + challengeList[0].vulgarity);
        } else {
          this.setState({ challenges: null, loading: false });
        }
      })
      .catch((error) => {
        console.log('error message', error.response);
      });
  };

  componentWillUnmount() {
    // this.props.firebase.challenges().off();
  }

  onChangeText = (event) => {
    this.setState({ text: event.target.value });
  };

  onCreatechallenge = (event, authUser) => {
    // this.props.firebase.challenges().push({
    //     text: this.state.text,
    //     userId: authUser.uid,
    //     createdAt: this.props.firebase.serverValue.TIMESTAMP,
    //   }
    // );

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditchallenge = (challenge, text) => {
    const { uid, ...challengeSnapshot } = challenge;

    // this.props.firebase.challenge(challenge.uid).set({
    //   ...challengeSnapshot,
    //   text,
    //   editedAt: this.props.firebase.serverValue.TIMESTAMP,
    // });
  };
  rating(number) {
    if (number === 1) {
      return 'PG';
    } else if (number === 2) {
      return 'PG-13';
    } else if (number === 3) {
      return 'R';
    } else if (number === 4) {
      return 'XXX';
    }
  }
  onRemovechallenge = (uid) => {
    this.props.firebase.challenge(uid).remove();
  };
  remove_challenge() {
    // this.props.firebase.challenge(uid).remove();
    const apiName = 'moomanagementRest';
    const path = '/api/challenges';
    const myInit = {
      // OPTIONAL
      body: { uid: this.state.uidDelete },
      headers: {}, // OPTIONAL
    };

    API.del(apiName, path, myInit)
      .then((challengeObject) => {
        console.log('challengeObject', challengeObject);
        // Add your code here
        if (challengeObject) {
          console.log('this.state.uidDelete', this.state.uidDelete);
          const challengeList = this.state.challenges.filter(
            (item) => item.uid !== this.state.uidDelete,
          );
          console.log('challengeList', challengeList);
          this.setState({
            challenges: challengeList,
            showDelete: false,
          });
          console.log('Loaded dataL:' + challengeList);
        } else {
          this.setState({ challenges: null, loading: false });
        }
      })
      .catch((error) => {
        console.log('error message', error.response);
      });
  }
  onNextPage = () => {
    this.setState(
      (state) => ({ limit: state.limit + 5 }),
      this.onListenForchallenges,
    );
  };
  rating(number) {
    if (number === 1) {
      return 'PG';
    } else if (number === 2) {
      return 'PG-13';
    } else if (number === 3) {
      return 'R';
    } else if (number === 4) {
      return 'XXX';
    }
  }
  filterCaseInsensitive = (filter, row) => {
    const id = filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
      return row[id] !== undefined
        ? String(row[id].toLowerCase()).includes(
            filter.value.toLowerCase(),
          )
        : true;
    }
  };
  edit_challenge(key) {
    this.setState({
      open: true,
      challenge: key.challenge,
      value: key.vulgarity,
      key: key.key,
    });
  }
  submit_edit() {
    if (this.state.key) {
      this.props.firebase.challenges().child(this.state.key).update({
        challenge: this.state.challenge,
        vulgarity: this.state.value,
      });
    }
  }
  handleCloseDelete() {
    this.setState({ showDelete: false });
  }
  handleShowDelete(uidDelete) {
    this.setState({ showDelete: true, uidDelete: uidDelete });
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleChallenge(event) {
    this.setState({ challenge: event.target.value });
  }
  render() {
    const { text, challenges, loading } = this.state;
    const columns = [
      {
        Header: 'Name',
        accessor: 'name', // String-based value accessors!
      },
      {
        Header: 'Age',
        accessor: 'age',
        Cell: (props) => (
          <span className="number">{props.value}</span>
        ), // Custom cell components!
      },
      {
        id: 'friendName', // Required because our accessor is not a string
        Header: 'Friend Name',
        accessor: (d) => d.vulgarity, // Custom value accessors!
      },
      {
        Header: (props) => <span>Friend Age</span>, // Custom header components!
        accessor: 'friend.age',
      },
    ];
    return (
      <div>
        {challenges && (
          <div
            style={{ alignContent: 'center', alignItems: 'center' }}
          >
            <br />

            <br />
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <AddChallenge
                title={'Add Challenge'}
                data={{ vulgarity: 3, challenge: '' }}
                style={{ alignSelf: 'center' }}
              />
            </div>
            <ReactTable
              data={this.state.challenges}
              headerStyle={{ position: 'fixed' }}
              filterable
              defaultFilterMethod={this.filterCaseInsensitive}
              columns={[
                {
                  columns: [
                    {
                      Header: 'Challenge',
                      id: 'challenge',
                      style: { 'white-space': 'unset' },
                      accessor: (d) => d.challenge,
                    },
                    {
                      Header: 'Vulgarity',
                      id: 'vulgarity',
                      accessor: (d) => this.rating(d.vulgarity),
                    },
                    {
                      Header: 'IG',
                      id: 'igHandle',
                      accessor: (d) => d.igHandle,
                    },
                    {
                      Header: 'Date',
                      id: 'Date',
                      accessor: (d) => d.date,
                    },
                    {
                      Header: 'By',
                      id: 'By',
                      accessor: (d) => d.by,
                    },
                    {
                      Header: '',
                      id: 'Delete',
                      filterable: false,
                      accessor: (row) => (
                        <div>
                          <Button
                            variant="outline-danger"
                            onClick={() =>
                              this.handleShowDelete(row.uid)
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Delete
                          </Button>
                        </div>
                      ),
                    },
                    {
                      Header: '',
                      id: 'Edit',
                      filterable: false,
                      accessor: (row) => (
                        <AddChallenge data={row} title={'Edit'} />
                      ),
                    },
                  ],
                },
              ]}
              defaultPageSize={20}
              style={{
                position: 'sticky',
              }}
              className="-striped -highlight"
            />
            <br />
            <Modal
              show={this.state.showDelete}
              onHide={() => this.handleCloseDelete()}
            >
              <Modal.Header closeButton>
                <Modal.Title>WARNING!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete that?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.handleCloseDelete()}
                >
                  No
                </Button>
                <Button
                  variant="primary"
                  onClick={() => this.remove_challenge()}
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}

        {!challenges && loading && (
          <div style={{ marginTop: 20 }}>Loading ...</div>
        )}
      </div>
    );
  }
}

export default Messages;
