/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLaPhrases = /* GraphQL */ `
  query GetLaPhrases($phrase: String!, $uuid: String!) {
    getLaPhrases(phrase: $phrase, uuid: $uuid) {
      createdAt
      phrase
      type
      uuid
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      createdAt
      expiration_date
      id
      last_edited_by
      message
      message_title
      message_type
      start_date
      updatedAt
    }
  }
`;
export const listLaPhrases = /* GraphQL */ `
  query ListLaPhrases(
    $filter: TableLaPhrasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLaPhrases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        phrase
        type
        uuid
      }
      nextToken
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        expiration_date
        id
        last_edited_by
        message
        message_title
        message_type
        start_date
        updatedAt
      }
      nextToken
    }
  }
`;
